import React from 'react';
import Box from "@mui/material/Box";
import firebase from "firebase/app";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withUserCount from "@xem/user-common/classes/withUserCount";
import { FirebaseAuthProvider } from "@xem/user-common/classes/firebase";


export const withFirebaseProcess = compose(
  withUserCount('userCount'),
  withProps(
    ({ signInWithPhoneNumber, element, connectWithRefreshToken })=> {
      const [view, setState] = React.useState(false);
      const [viewConfirm, setStateConfirm] = React.useState(false);
      const [confirmationResult, setConfirmationResult] = React.useState(null);
      return {
        view, viewConfirm, confirmationResult, setConfirmationResult,
        onDisplay: () => setState(!view),
        onDisplayConfirm: () => setStateConfirm(!viewConfirm),
        signInWithPhoneNumber: async (phone) => signInWithPhoneNumber({ phone, element, setConfirmationResult })
      }
    })
);

/**
 *
 * @param Component
 * @returns {function({element: *, firebaseSettings: *, [p: string]: *}): *}
 */
export const withFirebase = (Component) =>
  ({ firebaseSettings, ...props}) => {
    let element = React.useRef(null);
    return (<FirebaseAuthProvider {...{ ...firebaseSettings, firebase }}>
      <Component {...props} element={element} />
      <Box sx={{ display: 'none' }} id={"element"} ref={element} />
    </FirebaseAuthProvider>);
  }

export default withFirebase;
