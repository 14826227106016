import { graphql } from 'react-apollo';
import withProps from "recompose/withProps";
import LOGIN from "../graphql/Login.graphql";
import map from '@gqlapp/base/utils/map';
import loading from '@gqlapp/base/utils/loading';
import saveStatus from '@gqlapp/base/utils/saveStatus';
import Router from 'next/router'

export const withUserLogin = graphql(LOGIN, {
  // @ts-ignore
  props: ({ mutate, ownProps: { loadbar } }) => ({
    // @ts-ignore
    login: async ({ usernameOrEmail, password }) => loading(loadbar, async ()=> map(
      map(await mutate({variables: { input: { usernameOrEmail, password } }}),
        ({ data }: any)=> data),
      ({ login:res }:any)=> res
    ))
  })
});


export const withLoginQuery = withProps(({ currentUser })=>{
  const doLogin = async ({ key, value }) =>{
    let allCookies = getCookies();

    let keys = Object.keys(allCookies) || [];
    keys.filter((k)=>
      (new RegExp(/(SESS|SSESS)(.*)/s)).test(k))
      .map(function (value){
        deleteCookie(value);
      });

    await setCookie(key, value);
    setTimeout(()=>Router.reload(), 500)
  }

  const connectAction = async ({  action, errorMsg, variables: input }) => {
    saveStatus({ values: await action(input), errorMsg });
  }

  const loginAction = async ({  action, errorMsg, variables }) => {
    let { entity:{ session, ...userData } }: any = saveStatus({ values: await action(variables), errorMsg });
    currentUser.save( userData || {});
    await doLogin(session);
  }
  return { doLogin, loginAction, connectAction }
})

export default withUserLogin;
