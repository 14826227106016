import { withApollo } from 'react-apollo';
import { graphql } from 'react-apollo';
import SOCIAL_DISCONNECT from '../../graphql/social/socialDisconnect.graphql';
import FIREBASE from '../../graphql/social/firebase.graphql';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import map from '@gqlapp/base/utils/map';
import saveStatus from '@gqlapp/base/utils/saveStatus';
import firebase from 'firebase/app';

import { withLoginQuery } from '../withUserLogin';

export const withFirebaseConnect = graphql(FIREBASE, {
  props: ({ mutate }) => ({
    fbConnect: async (variables: any) => map(
      map(await mutate({variables}),
        ({ data }: any)=> data),
      ({ res }:any)=> res
    )
  })
});


export const withFirebaseDisConnect = graphql(SOCIAL_DISCONNECT, {
  props: ({ mutate }) => ({
    fbDisconnect: async (variables: any) => map(
      map(await mutate({variables}),
        ({ data }: any)=> data),
      ({ res }:any)=> res
    )
  })
});



// @ts-ignore
export const withFirebaseAuth = compose(
  withApollo,
  withLoginQuery,
  withFirebaseConnect,
  withFirebaseDisConnect,
  withProps(({ fbConnect: action, loginAction, connectAction, client }: any) => ({
    getToken: ()=> new Promise(async resolve => firebase
      .auth()
      .currentUser
      .getIdToken(true)
      .then(async idToken => resolve(idToken))
      // @ts-ignore
      .catch(()=>resolve())),
    signInWithRefreshToken: (refreshToken: any) =>
      loginAction({
        action,
        client,
        variables: { input: refreshToken },
        // @ts-ignore
        errorMsg: 'login.errorMsg'.t()
      }).catch(errorCatch),
    connectWithRefreshToken: (refreshToken: any) =>
      connectAction({
        action,
        client,
        history,
        variables: { input: refreshToken },
        // @ts-ignore
        errorMsg: 'login.errorMsg'.t()
      }).catch(errorCatch)
  })),
  withProps(({ signInWithRefreshToken, connectWithRefreshToken, refetch }: any) => ({
    auth: async ({ rest }: any) => {
      return rest
        .then(async (res: any) =>signInWithRefreshToken(res?.user?.refreshToken))
        .catch(errorCatch);
    },
    connect: async ({ rest }: any) => {
      return rest
        .then(async (res: any) => {
          await connectWithRefreshToken(res?.user?.refreshToken);
          refetch && refetch()
        })
        .catch(errorCatch);
    }
  })),
  withProps(({ auth, connect, fbDisconnect, refetch }: any) => ({
    signInWithPhoneNumber: async ({ phone, element, setConfirmationResult }: any) => {
      element.current.innerHTML = `<div id="recaptcha-container"/>`;
      let recaptcha:any = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
      await recaptcha.verify().then(async () => {
        firebase
          .auth()
          .signInWithPhoneNumber(phone, recaptcha)
          .then((res)=>{
            recaptcha.clear();
            setConfirmationResult(res)
          })
          .catch(errorCatch);
      }).catch(errorCatch);
    },
    /**
     * firebaseDisconnect
     * @param variables
     */
    firebaseDisconnect: async (variables: any) => {
      try {
        // @ts-ignore
        saveStatus({ values: await fbDisconnect(variables), errorMsg: 'Unknown error.'.t() });
        refetch && refetch();
      }catch (e) {
        errorCatch(e)
      }
    },
    signInWithGoogle: async () => {
      const authProvider = new firebase.auth.GoogleAuthProvider();
      return auth({ rest: firebase.auth().signInWithPopup(authProvider) });
    },
    signInWithFacebook: async () => {
      const authProvider = new firebase.auth.FacebookAuthProvider();
      return auth({ rest: firebase.auth().signInWithPopup(authProvider) });
    },

    connectWithGoogle: async () => {
      const authProvider = new firebase.auth.GoogleAuthProvider();
      return connect({ rest: firebase.auth().signInWithPopup(authProvider) });
    },
    connectWithFacebook: async () => {
      const authProvider = new firebase.auth.FacebookAuthProvider();
      return connect({ rest: firebase.auth().signInWithPopup(authProvider) });
    }
  }))
);
