import * as React from 'react';
import get from 'lodash/get';
import { renderAndAddProps } from 'render-and-add-props';

import { initializeFirebaseApp } from './initialize-firebase-app';
import { AuthEmission, FirebaseAuthProviderProps, FirebaseAuthProviderState, RenderableChildren } from './types';

const firebaseAuthProviderDefaultProps = {
  isSignedIn: false,
  providerId: null,
  user: null,
  firebase: {}
} as AuthEmission;

const { Provider: FirebaseAuthContextProvider, Consumer: FirebaseAuthContextConsumer } = React.createContext(
  firebaseAuthProviderDefaultProps
);

export class FirebaseAuthProvider extends React.PureComponent<FirebaseAuthProviderProps, FirebaseAuthProviderState> {
  public stopListeningToAuth?: () => void;
  // @ts-ignore
  public state = { isSignedIn: false, providerId: null, user: null, firebase: {} };
  constructor(props: FirebaseAuthProviderProps) {
    super(props);
    if (props.apiKey) {
      initializeFirebaseApp({ ...props });
    }
  }
  public listenToAuth = () => {
    const { firebase } = this.props;
    try{
      this.stopListeningToAuth = firebase
        .app()
        .auth()
        .onAuthStateChanged((user: any) => {
          let authEmission = null as null | AuthEmission;
          if (user === null) {
            authEmission = {
              isSignedIn: false,
              providerId: 'none',
              user,
              firebase
            };
          } else if (user.isAnonymous === true) {
            authEmission = {
              isSignedIn: true,
              providerId: 'anonymous',
              user,
              firebase
            };
          } else if (user.providerData && user.providerData[0]) {
            authEmission = {
              isSignedIn: true,
              providerId: get(user, 'providerData.0.providerId', 'unknown'),
              user,
              firebase
            };
          }
          if (authEmission !== null) {
            this.setState(() => authEmission);
          } else {
            console.error('Something unexpected happened with ', user);
          }
        });
    }catch (e) {

    }

  };
  public componentDidMount() {
    this.listenToAuth();
  }

  public componentWillUnmount() {
    // tslint:disable-next-line:no-unused-expression
    this.stopListeningToAuth && this.stopListeningToAuth();
  }
  public render() {
    // @ts-ignore
    const { children, apiKey } = this.props;
    return (
      <>
        {!!apiKey && (
          <FirebaseAuthContextProvider value={this.state}>
            {renderAndAddProps(children, {})}
          </FirebaseAuthContextProvider>
        )}

        {!apiKey && <>{children}</>}
      </>
    );
  }
}

// export type RenderableFunction<> = () => {}
// @ts-ignore
export const FirebaseAuthConsumer: React.StatelessComponent<{
  children: RenderableChildren;
}> = ({ children }) => {
  return (
    <FirebaseAuthContextConsumer>
      {(authState: AuthEmission) => renderAndAddProps(children, authState)}
    </FirebaseAuthContextConsumer>
  );
}

// @ts-ignore
export const IfFirebaseAuthed: React.StatelessComponent<{
  children: RenderableChildren;
}> = ({ children }) => {
  return (
    <FirebaseAuthContextConsumer>
      {(authState: AuthEmission) => (authState.isSignedIn === true ? renderAndAddProps(children, authState) : null)}
    </FirebaseAuthContextConsumer>
  );
};

export type FilterAuthFunction = (authState: AuthEmission) => boolean;

// @ts-ignore
export const IfFirebaseAuthedAnd: React.StatelessComponent<{
  filter: FilterAuthFunction;
  children: RenderableChildren;
}> = ({ children, filter }) => {
  return (
    <FirebaseAuthContextConsumer>
      {(authState: AuthEmission) =>
        authState.isSignedIn === true ? (filter(authState) ? renderAndAddProps(children, authState) : null) : null
      }
    </FirebaseAuthContextConsumer>
  );
};

// @ts-ignore
export const IfFirebaseAuthedOr: React.StatelessComponent<{
  children: RenderableChildren;
  filter: FilterAuthFunction;
}> = ({ children, filter }) => {
  return (
    <FirebaseAuthContextConsumer>
      {(authState: AuthEmission) =>
        authState.isSignedIn === true || filter(authState) ? renderAndAddProps(children, authState) : null
      }
    </FirebaseAuthContextConsumer>
  );
};

// @ts-ignore
export const IfFirebaseUnAuthed: React.StatelessComponent<{
  children: RenderableChildren;
}> = ({ children }) => {
  return (
    <FirebaseAuthContextConsumer>
      {(authState: AuthEmission) => (authState.isSignedIn === false ? renderAndAddProps(children, authState) : null)}
    </FirebaseAuthContextConsumer>
  );
};

export const WithFirebase = ({ children }: { children: RenderableChildren }) => {
  return <FirebaseAuthContextConsumer>{children}</FirebaseAuthContextConsumer>;
};
